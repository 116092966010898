import { combineReducers } from 'redux';
import activateRole from './roles/activateRoleReducer';
import activateMobileTower from './mobileTowers/activateMobileTowerReducer';
import addRole from './roles/addRoleReducer';
import addUser from './users/addUserReducer';
import addMobileTower from './mobileTowers/addMobileTowerReducer';
import addTenant from './mobileTowers/tenants/addTenantReducer';
import clientInfo from './clientInfoReducer';
import currentUser from './currentUserReducer';
import deactivateRole from './roles/deactivateRoleReducer';
import deactivateMobileTower from './mobileTowers/deactivateMobileTowerReducer';
import files from './filesReducer';
import fileUploadStatus from "./fileUploadStatusReducer";
import fullScreenStatus from './fullScreenStatusReducer';
import modules from "./roles/modulesReducer";
import regions from './mobileTowers/regionsReducer';
import roles from './roles/rolesReducer';
import toastNotification from './notifications/toastNotificationReducer';
import updateClientInfo from './updateClientInfoReducer';
import updateRole from './roles/updateRoleReducer';
import updateRolePermissions from './roles/updateRolePermissionsReducer';
import updateMobileTower from './mobileTowers/updateMobileTowerReducer';
import updateUser from './users/updateUserReducer';
import updateTenant from './mobileTowers/tenants/updateTenantReducer';
import users from './users/usersReducer';

import mobileTowers from "./mobileTowers/mobileTowersReducer";
import searchSuggestions from './searchResults/suggestReducer';

import forgotPassword from './forgotPasswordReducer';
import resetPassword from './resetPasswordReducer';
import tenants from './mobileTowers/tenants/tenantsReducer';

import places from './places/placesReducer';
import addPlace from './places/addPlaceReducer';
import updatePlace from './places/updatePlaceReducer';
import deactivatePlace from './places/deactivatePlaceReducer';
import activatePlace from './places/activatePlaceReducer';

import defects from './mobileTowers/defects/defectsReducer';

import recommendations from './mobileTowers/recommendations/recommendationsReducer';
import addRecommendation from './mobileTowers/recommendations/addRecommendationReducer';
import updateRecommendation from './mobileTowers/recommendations/updateRecommendation';
import activateRecommendation from './mobileTowers/recommendations/activateRecommendationReducer';
import deactivateRecommendation from './mobileTowers/recommendations/deactivateRecommendationReducer';
import addImageLabel from './mobileTowers/images/addImageLabelReducer';
import members from './mobileTowers/members/membersReducer'
import addMember from './mobileTowers/members/addMemberReducer';
import updateMember from './mobileTowers/members/updateMemberReducer';
import deactivateMember from './mobileTowers/members/deactivateMemberReducer';
import addTowerChecklist from './mobileTowers/images/addTowerChecklistReducer';

import gussetEndPlates from './mobileTowers/gussetEndPlates/gussetEndPlatesReducer';
import addGussetEndPlate from './mobileTowers/gussetEndPlates/addGussetEndPlateReducer';
import updateGussetEndPlate from './mobileTowers/gussetEndPlates/updateGussetEndPlateReducer';
import activateGussetEndPlate from './mobileTowers/gussetEndPlates/activateGussetEndPlateReducer';
import deactivateGussetEndPlate from './mobileTowers/gussetEndPlates/deactivateGussetEndPlateReducer';

import buttJoints from './mobileTowers/buttJointConnections/buttJointsReducer';
import addButtJoint from './mobileTowers/buttJointConnections/addButtJointReducer';
import updateButtJoint from './mobileTowers/buttJointConnections/updateButtJointReducer';
import activateButtJoint from './mobileTowers/buttJointConnections/activateButtJointReducer';
import deactivateButtJoint from './mobileTowers/buttJointConnections/deactivateButtJointReducer';

import flangeJoints from './mobileTowers/flangeJointConnections/flangeJointsReducer';
import addFlangeJoint from './mobileTowers/flangeJointConnections/addFlangeJointReducer';
import updateFlangeJoint from './mobileTowers/flangeJointConnections/updateFlangeJointReducer';
import activateFlangeJoint from './mobileTowers/flangeJointConnections/activateFlangeJointReducer';
import deactivateFlangeJoint from './mobileTowers/flangeJointConnections/deactivateFlangeJointReducer';

import addTowerSiteReport from './mobileTowers/images/addTowerSiteReportReducer';
import userAccess from './places/entitiesAccessReducer';
import addEntityAccess from './places/addEntityAccessReducer';
import companies from './company/companiesReducer';
import addCompany from './company/addCompanyReducer';
import updateCompany from './company/updateCompanyReducer';
import activateCompany from './company/activateCompanyReducer';
import deactivateCompany from './company/deactivateCompanyReducer';

import panelDetails from './mobileTowers/panels/panelDetailsReducer';
import addPanelDetail from './mobileTowers/panels/addPanelDetailsReducer';
import updatePanelDetail from './mobileTowers/panels/updatePanelDetailsReducer';
import deactivatePanelDetail from './mobileTowers/panels/deactivatePanelDetailReducer';

import towerAnalysis from './mobileTowers/analysisReducer';

import addTowerCompany from './company/addTowerCompanyReducer';

import activateTenant from './mobileTowers/tenants/activateTenantReducer';
import deactivateTenant from './mobileTowers/tenants/deactivateTenantReducer';

import materialSpecifications from './mobileTowers/materialSpecifications/materialSpecificationsReducer';
import updateMaterialSpecification from './mobileTowers/materialSpecifications/updateMaterialSpecificationReducer';

import userPreferences from './userPreferences/userPreferencesReducer';
import updateUserPreference from './userPreferences/updateUserPreferenceReducer';
import addTowerDrawing from './mobileTowers/images/addTowerDrawingReducer';

import auditsData from './mobileTowers/auditHistory/auditsDataReducer';
import addAudit from './mobileTowers/auditHistory/addAuditReducer';
import updateAudit from './mobileTowers/auditHistory/updateAuditReducer';
import deactivateAudit from './mobileTowers/auditHistory/deactivateAuditReducer';

import towerDesigns from './design/mobileTowerDesignReducer';
import addTowerDesigns from './design/addTowerDesignReducer';
import updateTowerDesigns from './design/updateTowerDesignReducer';
import activateTowerDesigns from './design/activateTowerDesignReducer';
import deactivateTowerDesigns from './design/deactivateTowerDesignReducer';

import calculatedForces from './mobileTowers/calculatedForce/calculatedForceReducer';
import addCalculatedForces from './mobileTowers/calculatedForce/addCalculatedForceReducer';
import updateCalculatedForces from './mobileTowers/calculatedForce/updateCalculatedForceReducer';
import deactivateCalculatedForces from './mobileTowers/calculatedForce/deactivateCalculatedForceReducer';

import connectionBolts from './mobileTowers/connection/connectionReducer';
import addConnectionBolts from './mobileTowers/connection/addConnectionReducer';
import updateConnectionBolts from './mobileTowers/connection/updateConnectionReducer';
import deactivateConnectionBolts from './mobileTowers/connection/deactivateConnectionReducer';

const rootReducer = history => combineReducers({
  activateRole,
  activateMobileTower,

  addRole,
  addUser,
  addMobileTower,
  addTenant,

  clientInfo,
  currentUser,

  deactivateRole,
  deactivateMobileTower,

  files,
  fileUploadStatus,
  fullScreenStatus,

  modules,
  roles,
  toastNotification,
  users,

  updateClientInfo,
  updateRole,
  updateRolePermissions,
  updateUser,
  updateMobileTower,
  updateTenant,

  mobileTowers,
  regions,

  searchSuggestions,

  forgotPassword,
  resetPassword,

  defects,
  tenants,

  places,
  addPlace,
  updatePlace,
  activatePlace,
  deactivatePlace,

  recommendations,
  addRecommendation,
  updateRecommendation,
  activateRecommendation,
  deactivateRecommendation,

  addImageLabel,
  addTowerChecklist,

  members,
  addMember,
  updateMember,
  deactivateMember,

  gussetEndPlates,
  addGussetEndPlate,
  updateGussetEndPlate,
  activateGussetEndPlate,
  deactivateGussetEndPlate,

  buttJoints,
  addButtJoint,
  updateButtJoint,
  activateButtJoint,
  deactivateButtJoint,

  flangeJoints,
  addFlangeJoint,
  updateFlangeJoint,
  activateFlangeJoint,
  deactivateFlangeJoint,

  addTowerSiteReport,

  userAccess,
  addEntityAccess,

  companies,
  addCompany,
  updateCompany,
  activateCompany,
  deactivateCompany,

  panelDetails,
  addPanelDetail,
  updatePanelDetail,
  deactivatePanelDetail,

  towerAnalysis,

  addTowerCompany,

  activateTenant,
  deactivateTenant,

  materialSpecifications,
  updateMaterialSpecification,

  userPreferences,
  updateUserPreference,

  addTowerDrawing,

  auditsData,
  addAudit,
  updateAudit,
  deactivateAudit,

  towerDesigns,
  addTowerDesigns,
  updateTowerDesigns,
  activateTowerDesigns,
  deactivateTowerDesigns,

  calculatedForces,
  addCalculatedForces,
  updateCalculatedForces,
  deactivateCalculatedForces,

  connectionBolts,
  addConnectionBolts,
  updateConnectionBolts,
  deactivateConnectionBolts,
  

});

export default rootReducer;

import React from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MdAccountCircle } from "react-icons/md";
import { EllipsisVerticalIcon, KeyIcon, LockClosedIcon } from "@heroicons/react/24/solid";
import TowersMenuCommon from "./TowersMenuCommon";
import RegionsTreeView from "../../MobileTowers/RegionsTreeView";
import { Link } from "react-router-dom";
import * as loginActions from '../../../actions/loginActions';
import { FaCircleUser } from "react-icons/fa6";
import { getFileUri } from "../../../utils/navigation";
import AdminMenu from "./AdminMenu";

function PACSNavigation({ currentUser, actions, toggleCreateModal }) {
    const userFullName = currentUser.userData.fullname;
    const userEmail = currentUser.userData.email;

    const logoutUser = () => {
        actions.logoutUser(0);
    };

    if (!currentUser.loggedIn) {
        return null;
    }

    let userProfilePic;
    if (currentUser.userData.files.length > 0) {
        const profilePicUrl = getFileUri(currentUser.userData.files[0].id);
        userProfilePic = (
            <img src={profilePicUrl} alt="User Profile Picture" className="w-10 h-10 inline rounded-full max-w-none" />
        );
    } else {
        userProfilePic = (
            <MdAccountCircle className="w-10 h-10 inline" />
        );
    }

    const isAdmin = (currentUser.userData.role && (currentUser.userData.role.shortCode === 'sadmin' || currentUser.userData.role.shortCode === 'admin'));
    // const isAdmin = false;

    return (
        <div className="bg-white h-screen flex flex-col max-h-screen">
            <div className="p-4">
                <img
                    src="/img/newMobile-towers/TrekkScan-logo.svg"
                    alt={"Application Logo"}
                    style={{ maxWidth: '150px' }}
                />
            </div>

            <div className="m-0 divider divider-primary" />

            <div className="side-nav-contents">
                <div className="p-2 pt-4 flex flex-row justify-between gap-1">
                    <div>
                        {userProfilePic}
                    </div>
                    <div className="grow">
                        <p className="text-black">{userFullName}</p>
                        <div>
                            <p className="text-xs">{userEmail}</p>
                        </div>
                    </div>

                    <div className="user-details dropdown dropdown-hover dropdown-end">
                        <div className="btn btn-ghost p-1">
                            <div>
                                <EllipsisVerticalIcon className="w-5 h-5 inline"/>
                            </div>
                        </div>
                        <ul className="p-0 text-black shadow menu dropdown-content bg-base-100 rounded-sm w-48">
                            <li className="text-sm">
                                <Link
                                    to="/profile"
                                    className="flex items-center p-2 text-xs font-normal rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                                >
                                    <FaCircleUser className="w-4 h-4 inline mr-1"/>
                                    Profile
                                </Link>
                            </li>

                            {/*<li className="text-sm">*/}
                            {/*  <a>Logout (Notifications)</a>*/}
                            {/*</li>*/}

                            <li className="text-sm">
                                <a
                                    href={null}
                                    onClick={logoutUser}
                                    className="flex items-center p-2 text-xs font-normal rounded-lg hover:text-white hover:bg-rose-500 dark:hover:bg-rose-500"

                                >
                                    <LockClosedIcon className="w-4 h-4 inline mr-1"/>
                                    Logout
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="m-0 divider divider-primary h-2"></div>

                <div>
                    <TowersMenuCommon/>
                    <div className="m-0 divider divider-primary"></div>
                </div>

                {isAdmin && (
                    <div>
                        <AdminMenu/>
                        <div className="m-0 divider divider-primary"></div>
                    </div>
                )}

                <div className="">
                    <div className="p-3 ml-2">
                        <RegionsTreeView toggleCreateModal={toggleCreateModal} currentUser={currentUser}/>
                    </div>

                    <div className="m-0 divider divider-primary"></div>
                </div>
            </div>
        </div>
    );
}

PACSNavigation.defaultProps = {
    actions: {},
    currentUser: {},
};

PACSNavigation.propTypes = {
    actions: PropTypes.object,
    currentUser: PropTypes.object,
};

const mapStateToProps = state => ({
    currentUser: state.currentUser,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, loginActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PACSNavigation);


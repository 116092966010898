import React from "react";
import {
    GET_PANEL_DETAILS_STARTING,
    GET_PANEL_DETAILS_SUCCESS,
    GET_PANEL_DETAILS_ERROR,
    ADD_PANEL_DETAILS_STARTING,
    ADD_PANEL_DETAILS_SUCCESS,
    ADD_PANEL_DETAILS_ERROR,
    UPDATE_PANEL_DETAILS_STARTING,
    UPDATE_PANEL_DETAILS_SUCCESS,   
    UPDATE_PANEL_DETAILS_ERROR,
    DEACTIVATE_PANEL_DETAILS_STARTING,
    DEACTIVATE_PANEL_DETAILS_SUCCESS,
    DEACTIVATE_PANEL_DETAILS_ERROR
} from "../constants/actionTypes";

import {
    GET_PANEL_DETAILS,
    ADD_PANEL,
    UPDATE_PANEL,
    DEACTIVATE_PANEL
} from '../constants/apiUrls';

import axios from "axios";

export function getPanelDetailsStarting(mobileTowerId){
    return {type: GET_PANEL_DETAILS_STARTING, mobileTowerId}
}

export function getPanelDetailsSuccess(mobileTowerId, data) {
    return {type: GET_PANEL_DETAILS_SUCCESS, mobileTowerId, data}
}

export function getPanelDetailsError(mobileTowerId, err) {
    return {type : GET_PANEL_DETAILS_ERROR, mobileTowerId, err}
}

export function getPanelDetailsInfo(mobileTowerId) {
    return (dispatch) => {
        dispatch(getPanelDetailsStarting(mobileTowerId));
        const url = GET_PANEL_DETAILS.replace('{towerId}', mobileTowerId);
        axios.get(url).then((response) => {
            if (response.status === 200 && response.data.success) {                
                dispatch(getPanelDetailsSuccess(mobileTowerId, response.data));
                return;
            }
            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to get mobile tower panel details';
            dispatch(getPanelDetailsError(mobileTowerId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(getPanelDetailsError(mobileTowerId, error));
        });
    };
}

export function addPanelDetailStarting(towerId){
    return { type : ADD_PANEL_DETAILS_STARTING , towerId}
}

export function addPanelDetailSuccess(towerId,data){
    return { type : ADD_PANEL_DETAILS_SUCCESS, towerId,data}
}

export function addPanelDetailError(towerId,err){
    return { type : ADD_PANEL_DETAILS_ERROR, towerId, err}
}

export function addPanelDetail(towerId,postData){
    return(dispatch)=>{
        dispatch(addPanelDetailStarting(towerId))
        const url = ADD_PANEL.replace('{towerId}', towerId);
        axios.post(url, postData).then((response)=>{
            if(response.status == 200 && response.data.success){
                dispatch(addPanelDetailSuccess(towerId, response.data))
                return;
            }
            const err = (response && response.data && response.data.error) ?
            response.data.error : 'Failed to add panel detail'
            dispatch(addPanelDetailError(towerId, err));
        }).catch((err)=>{
            const Error = (err.response && err.response.data && err.data.response.error) ?
            err.data.response.error : err.message;
            dispatch(addPanelDetailError(towerId, Error));
        })
    }
}

export function updatePanelDetailsStarting(towerId, panelId) {
    return {type : UPDATE_PANEL_DETAILS_STARTING, towerId, panelId}
}

export function updatePanelDetailsSuccess(towerId, panelId, data) {
    return {type : UPDATE_PANEL_DETAILS_SUCCESS, towerId, panelId, data}
}

export function updatePanelDetailsError(towerId, panelId, err) {
    return {type : UPDATE_PANEL_DETAILS_ERROR, towerId, panelId, err}
}

export function updatePanelDetail(towerId, panelId, postData){
    return(dispatch)=>{
        dispatch(updatePanelDetailsStarting(towerId, panelId))
        const url = UPDATE_PANEL.replace('{towerId}', towerId).replace('{panelId}', panelId);
        axios.put(url, postData).then((response)=>{
            if(response.status == 200 && response.data.success){
                dispatch(updatePanelDetailsSuccess(towerId, panelId, response.data))
                return;
            }
            const err = (response && response.data && response.data.error) ?
            response.data.error : 'Failed to Update panel detail'
            dispatch(updatePanelDetailsError(towerId, panelId, err));
        }).catch((err)=>{
            const error = (err.response && err.response.data && err.data.response.error) ?
            err.data.response.error : err.message;
            dispatch(updatePanelDetailsError(towerId, panelId, error));
        })
    }
}


export function deactivatePanelDetailsStarting(towerId, panelId) {
    return {type : DEACTIVATE_PANEL_DETAILS_STARTING, towerId, panelId}
}

export function deactivatePanelDetailsSuccess(towerId, panelId, data) {
    return {type : DEACTIVATE_PANEL_DETAILS_SUCCESS, towerId, panelId, data}
}

export function deactivatePanelDetailsError(towerId, panelId, err) {
    return {type : DEACTIVATE_PANEL_DETAILS_ERROR, towerId, panelId, err}
}

export function deactivatePanelDetail(towerId, panelId, postData){
    return(dispatch)=>{
        dispatch(deactivatePanelDetailsStarting(towerId, panelId))
        const url = DEACTIVATE_PANEL.replace('{towerId}', towerId).replace('{panelId}', panelId)
        axios.delete(url, postData).then((response)=>{
            if(response.status == 200 && response.data.success){
                dispatch(deactivatePanelDetailsSuccess(towerId, panelId, response.data))
                return;
            }
            const err = (response && response.data && response.data.error) ?
            response.data.error : 'Failed to deactivate panel detail'
            dispatch(deactivatePanelDetailsError(towerId, panelId, err));
        }).catch((err)=>{
            const error = (err.response && err.response.data && err.data.response.error) ?
            err.data.response.error : err.message;
            dispatch(deactivatePanelDetailsError(towerId, panelId, error));
        })
    }
}



export const env = 'development';

// export const appUrl = 'http://localhost:3000';
export const appUrl = 'https://9t6.in';
// export const socketUrl = 'https://data.' + document.domain + '';
// export const socketUrl = 'http://' + document.domain + ':8888/';
export const socketUrl = 'http://' + document.domain + ':8888/';
export const apiUrl = 'https://api.' + document.domain + '/api';
// export const apiUrl = 'http://' + document.domain + ':3333/api';
export const searchApiUrl = 'https://ananth.cc';
export const brandName = 'TrekkScan';
export const companyName = 'PrincipleACS';
export const copyrightYear = 2016;
export const pageTitleSuffix = ` | ${brandName}`;
export const appVersion = '2.0.1';
export const googleMapsApiKey = 'AIzaSyC1PRBaLg2jZ0R82zM7tNyvZB5On8Ny9DM';
export const bingMapsApiKey = 'Aj4QQqpu0-17k6H6OXE4KFoSshs4utO1k3WXIWD_BObfRKIy5jkD0K8Jn6Upe0ZA';

export const appFeatures = {
  fullScreenButton: false,
  notifications: true,
  reportInPopup: false,
  requireAuthorization: false,
  search: true,
  shop: true,
};

export const utcOffset = 330;
export const valueNA = 'NA1';

export const dateFormats = {
  timeZone: 'Asia/Kolkata',

  in: {
    date: 'YYYY-MM-DD',
    time: 'HH:mm:ss',
    datetime: 'YYYY-MM-DD HH:mm:ss',
    iso8601: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  },

  out: {
    date: 'MMM D, YYYY',
    time: 'HH:mm',
    time_meridian: 'hh:mm A',
    date_proper: 'DD-MM-YYYY',
    datetime: 'DD-MM-YYYY hh:mm A',
    date_fns: 'dd-MM-yyyy',
    datetime_fns: 'dd-MM-yyyy HH:mm',
  },

  blog: {
    date: 'MMM D, YYYY',
  },
};

export const twentyOneCfrConfig = {
  minUsernameLength: 5,
  minPasswordLength: 6,
  autoLogoutInterval: 5,
};

import React from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { FaHeartbeat } from "react-icons/fa";
import { IoDocumentLockSharp } from "react-icons/io5";
import { Link } from "react-router-dom";

function TowersMenuCommon() {

    return (
        <div className="p-3">
            <div>
                <p className="text-sm ml-2 mb-2">Dashboards</p>
            </div>
            <div className="p-1 ml-2 flex flex-row text-black hover:bg-slate-200 rounded-lg">
                <div>
                    <img src="/img/tower-icons/CircleChart.png" alt="icon png" className="w-4 h-4 inline mr-2" />
                </div>
                <div className="mt-1 text-sm">
                    <Link to={"/"}>
                        Portfolio Analysis
                    </Link>
                </div>
            </div>
            <div className="p-1 ml-2 flex flex-row text-black hover:bg-slate-200 rounded-lg">
                <div>
                    <FaHeartbeat className="w-4 h-4 inline mr-2" />
                </div>
                <div className="mt-1 text-sm">
                    <a href="">Health Analysis</a>
                </div>
            </div>
            <div className="p-1 ml-2 flex flex-row text-black hover:bg-slate-200 rounded-lg">
                <div>
                    <img src="/img/tower-icons/GPSAntenna.png" alt="icon png" className="w-4 h-4 inline mr-2" />
                </div>
                <div className="mt-1 text-sm">

                    <Link to={"/page/mobiletowerstenancypage"}>
                        Tenants Info
                    </Link>
                </div>
            </div>
            <div className="p-1 ml-2 flex flex-row text-black hover:bg-slate-200 rounded-lg">
                <div>
                    <IoDocumentLockSharp className="w-4 h-4 inline mr-2" />
                </div>
                <div className="mt-1.5 text-sm">
                    <a href="">Reports</a>
                </div>
            </div>
        </div>
    );
}

TowersMenuCommon.defaultProps = {
    actions: {},
};

TowersMenuCommon.propTypes = {
    actions: PropTypes.object,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TowersMenuCommon);


import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
    GET_REGIONS_ERROR,
    GET_REGIONS_STARTING,
    GET_REGIONS_SUCCESS, REGION_TREE_STATE_CHANGED,
    REGIONS_COMPANY_CHANGED,
    ADD_TOWER_COMPANY_SUCCESS,
} from '../../constants/actionTypes';

function getTowerCount(placeId, state) {
    const { hierarchy, towersMap } = state;

    let directTowerCount = 0;
    if (towersMap[placeId]) {
        directTowerCount = towersMap[placeId].filter((towerId) => {
            if (state.companyId === 0) {
                return true;
            }

            const tower = state.towersData[towerId];
            return tower && tower.companyId === state.companyId;
        }).length;
    }

    if (!hierarchy[placeId]) {
        return directTowerCount;
    }

    let childTowerCount = 0;
    for (const childPlaceId of hierarchy[placeId]) {
        childTowerCount += getTowerCount(childPlaceId, state);
    }

    return directTowerCount + childTowerCount;
}

function getFinalHierarchy(state) {
    const { hierarchy, ids: placeIds } = state;
    const towerCounts = {};

    for (const placeId of placeIds) {
        towerCounts[placeId] = getTowerCount(placeId, state);
    }

    towerCounts["0"] = getTowerCount("0", state);

    const newHierarchy = {};
    for (const placeId of Object.keys(hierarchy)) {
        const count = towerCounts[placeId];
        if (!count) {
            continue;
        }

        newHierarchy[placeId] = hierarchy[placeId].filter((childPlaceId) => {
            return towerCounts[childPlaceId] > 0;
        });
    }

    return [newHierarchy, towerCounts];
}

export default function regionsReducer(state = initialState.regions, action) {
    switch (action.type) {
        case GET_REGIONS_STARTING: {
            const newState = cloneDeep(state);
            newState.loading = true;
            newState.loaded = false;
            return newState;
        }

        case GET_REGIONS_SUCCESS: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = true;

            newState.ids = action.data.placeIds;
            newState.map = action.data.places;
            newState.userAddress = action.data.userAddress;
            newState.hierarchy = action.data.mapping;
            newState.towersMap = action.data.placeTowersMap;
            newState.towersData = action.data.towers;

            const [newHierarchy, towerCounts] = getFinalHierarchy(newState);
            newState.towerCounts = towerCounts;
            newState.finalHierarchy = newHierarchy;

            // const places = newState.map

            // TODO Set showInternational flag
            // newState.showInternational = (newState.userAddress && )

            return newState;
        }

        case REGIONS_COMPANY_CHANGED: {
            const newState = cloneDeep(state);
            newState.companyId = action.companyId;

            const [newHierarchy, towerCounts] = getFinalHierarchy(newState);
            newState.towerCounts = towerCounts;
            newState.finalHierarchy = newHierarchy;

            return newState;
        }

        case REGION_TREE_STATE_CHANGED: {
            const newState = cloneDeep(state);
            const { placeId, open } = action;
            newState.treeState[placeId] = open;
            return newState;
        }

        case GET_REGIONS_ERROR: {
            const newState = cloneDeep(state);
            newState.loading = false;
            newState.loaded = false;
            newState.error = action.err ? action.err : 'Failed to retrieve regions.';
            return newState;
        }

        case ADD_TOWER_COMPANY_SUCCESS: {
            const newState = cloneDeep(state);
            const {mobileTowerId, companyId} = action;
            if (newState.towersData[mobileTowerId]) {
                newState.towersData[mobileTowerId].companyId = companyId;
            }

            return newState;
        }

        default: {
            return state;
        }
    }
}

import slugify from 'slugify';
import {apiUrl} from "../constants/appConfig";

export function getQueryParamsFromEncodedUrl(url) {
  if(!url) url = window.location.search;
  var query = url.substr(1);
  var result = {};
  query.split("&").forEach(function(part) {
    var item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });

  if (!result.p) {
    return {};
  }

  query = result.p;
  result = JSON.parse(atob(query));
  return result;
}

export function openDashboard(navigate) {
  navigate('/');
}

export function getFileUri(fileId) {
  return `${apiUrl}/media/${fileId}`;
}

export function getThumbUri(fileId, dimens) {
  return `${apiUrl}/media/${fileId}/resize/${dimens}`;
}

export function getDownloadUri(fileId) {
  return `${apiUrl}/media/${fileId}/download`;
}

export function goToTowerPage(navigate, mobileTower) {
  navigate(`/towers/${mobileTower.id}/${slugify(mobileTower.siteIdStr)}`);
}

export function goToPlacePage(navigate, place) {
  navigate(`/places/${place.id}/${slugify(place.name)}`);
}

export function openSearchResult(navigate, query) {
  navigate(`/search/${query}`);
}

export function openLogin(navigate) {
  navigate(`/login`);
}

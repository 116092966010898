import {
    GET_REGIONS_ERROR,
    GET_REGIONS_STARTING,
    GET_REGIONS_SUCCESS,
    REGION_TREE_STATE_CHANGED,
    REGIONS_COMPANY_CHANGED,
} from "../../constants/actionTypes";
import axios from "axios";
import {
    GET_REGIONS,
} from "../../constants/apiUrls";

export function getRegionsStarting() {
    return { type: GET_REGIONS_STARTING };
}

export function getRegionsSuccess(data) {
    return { type: GET_REGIONS_SUCCESS, data };
}

export function getRegionsError(err) {
    return { type: GET_REGIONS_ERROR, err };
}

export function getRegions() {
    return (dispatch) => {
        dispatch(getRegionsStarting());

        axios.get(GET_REGIONS).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(getRegionsSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to get regions';
            dispatch(getRegionsError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(getRegionsError(error));
        });
    };
}

export function onCompanyIdChanged(companyId) {
    return { type: REGIONS_COMPANY_CHANGED, companyId };
}

export function regionTreeStateChanged(placeId, open) {
    return { type: REGION_TREE_STATE_CHANGED, placeId, open };
}

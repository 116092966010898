import { map } from "lodash";
import { deactivatePanelDetail } from "../actions/panelActions";

const initialState = {
  currentUser: {
    prevRoute: '',

    loading: false,
    loaded: false,

    refetchInfo: false,
    fetchingInfo: true,
    forceLogin: false,
    loggedIn: false,
    loginInProgress: false,

    error: '',
    loggedInElsewhere: false,

    userData: {},
    rolePermissions: {},
    tokenData: {},

    userId: '',
    token: '',
  },

  clientInfo: {
    loading: false,
    loaded: false,
    data: {},
  },

  fileUploadStatus: {
    uploading: false,
    uploaded: false,
    error: null,
    file: null,
  },

  files: {
    fileId: {
      loading: false,
      loaded: false,
      error: null,

      file: {},
    },
  },

  fullScreenStatus: {
    enabled: false,
  },

  updateClientInfo: {
    saving: false,
    saved: false,
    error: null,
  },

  addMobileTower: {
    saving: false,
    saved: false,
    error: null,
    newMobileTower: null,
  },

  updateMobileTower: {
    mobileTowerId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  roles: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  modules: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addRole: {
    saving: false,
    saved: false,
    error: null,
    newRole: null,
  },

  updateRole: {
    roleId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  users: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addUser: {
    saving: false,
    saved: false,
    error: null,
    newUser: null,
  },

  updateUser: {
    userId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  updateRolePermissions: {
    roleId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateCategory: {
    categoryId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateMobileTower: {
    mobileTowerId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateMobileTower: {
    mobileTowerId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateRole: {
    roleId: {
      saving: false,
      saved: false,
      error: null,
    },
  },


  deactivateRole: {
    roleId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  userData: {
    map: {},
  },

  auditLogs: {
    ids: [],
    map: {},
    pagedIds: [],
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  toastNotification: {
    id: 0,
    data: null,
  },

  mobileTowers: {
    ids: [],
    inactiveIds: [],
    map: {},
    defectsMap: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  regions: {
    ids: [],
    inactiveIds: [],
    userAddress: null,
    companyId: 0,
    finalHierarchy: {},
    showInternational: false,
    treeState: {},
    map: {},
    hierarchy: {},
    towersMap: {},
    towerCounts: {},
    towersData: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  searchSuggestions: {
    query: {
      loading: false,
      loaded: false,
      error: null,

      suggestions: [],
      links: {},
      resultsData: {},
    },
  },

  forgotPassword: {
    saving: false,
    saved: false,
    error: null,
  },

  resetPassword: {
    saving: false,
    saved: false,
    error: null,
    newPassword: null,
  },

  tenants: {
    ids: [],
    inactiveIds: [],
    map: {},
    typeWise: {
      'GSM': [],
      'RRU': [],
      'Micro-wave': [],
    },

    typeWiseSr: {
      'GSM': [],
      'RRU': [],
      'Micro-wave': [],
    },

    typeWiseSpare: {
      'GSM': [],
      'RRU': [],
      'Micro-wave': [],
    },

    heightWise: {},
    heightWiseSr: {},
    heightWiseSpare: {},

    legWise: {},
    legWiseSr: {},
    legWiseSpare: {},

    existingIds: [],
    srIds: [],
    spareIds: [],

    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  addTenant: {
    saving: false,
    saved: false,
    error: null,
    newTenant: null,
  },

  updateTenant: {
    tenantId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  places: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addPlace: {
    saving: false,
    saved: false,
    error: null,
    newPlace: null,
  },

  updatePlace: {
    placeId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activatePlace: {
    placeId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivatePlace: {
    placeId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  defects: {
    ids: [],
    map: {},
    groups: [],
    groupWiseMap: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  recommendations: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addRecommendation: {
    saving: false,
    saved: false,
    error: null,
    newRecommendation: null,
  },

  updateRecommendation: {
    recommendationId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateRecommendation: {
    recommendationId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateRecommendation: {
    recommendationId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  addImageLabel: {
    saving: false,
    saved: false,
    error: null,
  },

  addTowerChecklist: {
    saving: false,
    saved: false,
    error: null,
  },

  members: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  addMember: {
    saving: false,
    saved: false,
    error: null,
    newMember: null,
  },

  updateMember: {
    memberId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateMember: {
    memberId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  gussetEndPlates: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  addGussetEndPlate: {
    saving: false,
    saved: false,
    error: null,
    newGussetEndPlate: null,
  },

  updateGussetEndPlate: {
    gussetEndPlateId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateGussetEndPlate: {
    gussetEndPlateId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateGussetEndPlate: {
    gussetEndPlateId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  addButtJoint: {
    saving: false,
    saved: false,
    error: null,
    newButtJoint: null,
  },

  buttJoints: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  updateButtJoint: {
    buttJointId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateButtJoint: {
    buttJointId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateButtJoint: {
    buttJointId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  flangeJoints: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
    filterKey: '',
  },

  addFlangeJoint: {
    saving: false,
    saved: false,
    error: null,
    newFlangeJoint: null,
  },

  updateFlangeJoint: {
    flangeJointId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateFlangeJoint: {
    flangeJointId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateFlangeJoint: {
    flangeJointId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  addTowerSiteReport: {
    saving: false,
    saved: false,
    error: null,
  },

  addTowerDrawing: {
    saving: false,
    saved: false,
    error: null,
  },

  userAccess: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addEntityAccess: {
    saving: false,
    saved: false,
    error: null,
    newEntityAccess: null,
  },

  uploadClientLogo: {
    id: {
      loading: false,
      loaded: false,
      error: null,
      ClientLogo: {},
    },
  },

  companies: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addCompany: {
    saving: false,
    saved: false,
    error: null,
    newCompany: null,
  },

  updateCompany: {
    companyId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateCompany: {
    companyId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateCompany: {
    companyId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  panelDetails: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addPanelDetail: {
    saving: false,
    saved: false,
    error: null,
    newPanelData: null,
  },

  updatePanelDetail: {
    panelId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivatePanelDetail: {
    panelId: {
      saving: false,
      saved: false,
      error: null,
    },
  },


  towerAnalysis: {
    towerId: {
      analysisType: {
        loading: false,
        loaded: false,

        data: [],
      },
    },
  },

  addTowerCompany: {
    saving: false,
    saved: false,
    error: null,
    newCompany: null,
  },

  activateTenant: {
    tenantId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateTenant: {
    tenantId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  materialSpecifications: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  updateMaterialSpecification: {
    materialSpecificationId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  userPreferences: {
    ids: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
  },

  updateUserPreference: {
    userPreferenceId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  auditsData: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addAudit: {
    saving: false,
    saved: false,
    error: null,
    newAudit: null,
  },

  updateAudit: {
    auditId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateAudit: {
    auditId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  towerDesigns: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addTowerDesigns: {
    saving: false,
    saved: false,
    error: null,
    newMobileTowerDesign: null,
  },

  updateTowerDesigns: {
    designId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  activateTowerDesigns: {
    designId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateTowerDesigns: {
    designId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  calculatedForces: {
      ids: [],
      inactiveIds: [],
      map: {},
      loading: false,
      loaded: false,
      error: null,
      isLastPage: false,
      page: 1,
      limit: 10,
      pages: 1,
      total: 0,
  },


  addCalculatedForces: {
    saving: false,
    saved: false,
    error: null,
    newCalculatedForce: null,
  },

  updateCalculatedForces: {
    calId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateCalculatedForces: {
    calId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  connectionBolts: {
    ids: [],
    inactiveIds: [],
    map: {},
    loading: false,
    loaded: false,
    error: null,
    isLastPage: false,
    page: 1,
    limit: 10,
    pages: 1,
    total: 0,
  },

  addConnectionBolts: {
    saving: false,
    saved: false,
    error: null,
    newCalculatedForce: null,
  },

  updateConnectionBolts: {
    connectionId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deactivateConnectionBolts: {
    connectionId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

};

export default initialState;

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import * as loginActions from "../../../actions/loginActions";
import { FaMapPin, FaUsersCog } from "react-icons/fa";
import { HiBuildingOffice2 } from "react-icons/hi2";
import { TbFile3D } from "react-icons/tb";

function AdminMenu({ currentUser }) {
  // TODO from role permissions
  const canManageUsers = true;

  return (
    <div className="p-3">
      <div>
        <p className="text-sm ml-2 mb-2">Administration</p>
      </div>

      {canManageUsers && (
        <div className="grid grid-cols-1 gap-0">
          <div className="p-1 ml-2 flex flex-row text-black hover:bg-slate-200 rounded-lg">
            <div>
              <FaUsersCog className="w-4 h-4 inline mr-2" />
            </div>
            <div className="mt-1 text-sm">
              <Link to={"/manage/users"}>Manage Users</Link>
            </div>
          </div>

          <div className="p-1 ml-2 flex flex-row text-black hover:bg-slate-200 rounded-lg">
            <div>
              <FaMapPin className="w-4 h-4 inline mr-2" />
            </div>
            <div className="mt-1 text-sm">
              <Link to={"/manage/places"}>Manage Places</Link>
            </div>
          </div>

          <div className="p-1 ml-2 flex flex-row text-black hover:bg-slate-200 rounded-lg">
            <div>
              <HiBuildingOffice2 className="w-4 h-4 inline mr-2" />
            </div>
            <div className="mt-1 text-sm">
              <Link to={"/manage/customers"}>Manage Customers</Link>
            </div>
          </div>
          <div className="p-1 ml-2 flex flex-row text-black hover:bg-slate-200 rounded-lg">
            <div>
              <TbFile3D className="w-4 h-4 inline mr-2"/>
            </div>
            <div className="mt-1 text-sm">
              <Link to={"/manage/designs"}>Manage Designs</Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

AdminMenu.defaultProps = {
  actions: {},
  currentUser: {},
};

AdminMenu.propTypes = {
  actions: PropTypes.object,
  currentUser: PropTypes.object,
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Object.assign({}, loginActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminMenu);
